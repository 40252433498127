<template>
    <div class="box">
        <div class="top">
            <i class="el-icon-arrow-left" @click="back"></i>
            <div class="title">用户协议</div>
        </div>
        <div class="comtent">
            <img src="../assets/img/agreement.png" alt="" srcset="">
        </div>
        <div class="footer">
        </div>
    </div>
</template>
<script>
export default {
    mounted() {
        this.setPageRem();
        window.onresize = () => {
        this.setPageRem();
        }
    },
    methods: {
        setPageRem() {
        var width = document.documentElement.clientWidth || document.body.clientWidth;
        document.getElementsByTagName("html")[0].style.fontSize = (width / 10) + "px";
        },
        back(){
            this.$router.go(-1)
        }
    },
}
</script>
<style lang="less" scoped>
.box{
    .top{
        height: 1.333rem;
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        .el-icon-arrow-left{
            position: absolute;
            left: 0.453rem;
            font-size: 0.533rem;
            font-weight: 700;
        }

    }
    .comtent{
        padding: 0.3rem 0.373rem;
        img{
            width: 100%;
        }
    }
}
</style>